<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <multiselect
              v-model="enterprise"
              track-by="id"
              label="enterprise_name"
              placeholder="Selecciona una empresa para iniciar"
              :options="enterprises"
              :searchable="true"
              :allow-empty="false"
            >
            </multiselect>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";

export default {
  name: "SelectEnterprise",
  data() {
    return {
      enterprise: null,
      enterprises: [],
    };
  },
  methods: {
    getEnterprises() {
      ApiService.get("user-profile-enterprise/user").then((res) => {
        this.enterprises = res.data;
      });
    },
  },
  mounted() {
    console.log(this.userId);
    this.getEnterprises();
  },
};
</script>

<style>
#kt_aside {
  /*  display: none !important;*/
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
